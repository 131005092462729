import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import './CustomerPagesManagement.css';

const CustomerPagesManagement = () => {
    const [customerPages, setCustomerPages] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [pages, setPages] = useState([]);
    const [formData, setFormData] = useState({
        customer_id: '',
        page_id: '',
        custom_url: '',
        custom_schema_name: '',
        custom_table_name: ''
    });
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchCustomers();
        fetchPages();
        fetchCustomerPages();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customers');
            }
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error('Error fetching customers:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchPages = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch pages');
            }
            const data = await response.json();
            setPages(data);
        } catch (error) {
            console.error('Error fetching pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchCustomerPages = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-pages-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customer pages');
            }
            const data = await response.json();
            setCustomerPages(data);
        } catch (error) {
            console.error('Error fetching customer pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // If adding a new record (not editing), check for duplicates
        if (!isEditing) {
            const exists = customerPages.some(cp =>
                cp.customer_id.toString() === formData.customer_id.toString() &&
                cp.page_id.toString() === formData.page_id.toString()
            );
            if (exists) {
                showAlert('This customer already has the selected page assigned.', 'error');
                return;
            }
        }

        try {
            let url = 'https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-pages-crud';
            let method = 'POST';
            if (isEditing) {
                url += `/${formData.customer_page_id}`;
                method = 'PUT';
            }

            const { customer_page_id, ...payload } = formData;

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save customer page');
            }

            await fetchCustomerPages();
            resetFormPartial(); // Reset only certain fields but keep customer_id
            showAlert(isEditing ? 'Customer page updated successfully' : 'Customer page added successfully', 'success');
        } catch (error) {
            console.error('Error saving customer page:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleEdit = (cp) => {
        setFormData(cp);
        setIsEditing(true);
    };

    const handleDelete = async (customer_page_id) => {
        if (window.confirm('Are you sure you want to delete this customer page?')) {
            try {
                const response = await fetch(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-pages-crud/${customer_page_id}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete customer page');
                }
                await fetchCustomerPages();
                showAlert('Customer page deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting customer page:', error);
                showAlert(error.message, 'error');
            }
        }
    };

    const resetFormPartial = () => {
        // Keep the selected customer, reset others
        setFormData(prev => ({
            ...prev,
            page_id: '',
            custom_url: '',
            custom_schema_name: '',
            custom_table_name: ''
        }));
        setIsEditing(false);
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    // Filter customerPages based only on the selected customer_id
    const filteredCustomerPages = customerPages.filter(cp => {
        if (!formData.customer_id) {
            return false;
        }
        return cp.customer_id.toString() === formData.customer_id.toString();
    });

    return (
        <div className="admin-container">
            <div className="admin-content">
                <AdminSidebar />
                <div className="admin-main-content">
                    <h2>Manage Customer Pages</h2>
                    {alert.show && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <div className="customer-pages-form-container">
                        <form onSubmit={handleSubmit} className="customer-pages-form">
                            <select name="customer_id" value={formData.customer_id} onChange={handleInputChange} required>
                                <option value="">Select Customer</option>
                                {customers.map(c => (
                                    <option key={c.customer_id} value={c.customer_id}>
                                        {c.customer_name} ({c.customer_email})
                                    </option>
                                ))}
                            </select>
                            <select name="page_id" value={formData.page_id} onChange={handleInputChange} required>
                                <option value="">Select Page</option>
                                {pages.map(p => (
                                    <option key={p.page_id} value={p.page_id}>
                                        {p.page_label} ({p.page_name})
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="custom_url"
                                value={formData.custom_url}
                                onChange={handleInputChange}
                                placeholder="Custom URL"
                            />
                            <input
                                type="text"
                                name="custom_schema_name"
                                value={formData.custom_schema_name}
                                onChange={handleInputChange}
                                placeholder="Custom Schema Name"
                            />
                            <input
                                type="text"
                                name="custom_table_name"
                                value={formData.custom_table_name}
                                onChange={handleInputChange}
                                placeholder="Custom Table Name"
                            />
                            <button type="submit">{isEditing ? 'Update' : 'Add'} Customer Page</button>
                            {isEditing && <button type="button" onClick={resetFormPartial}>Cancel</button>}
                        </form>
                    </div>
                    <table className="customer-pages-table">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Page</th>
                                <th>Custom URL</th>
                                <th>Custom Schema</th>
                                <th>Custom Table</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCustomerPages.map((cp) => {
                                const customer = customers.find(c => c.customer_id === cp.customer_id);
                                const page = pages.find(p => p.page_id === cp.page_id);
                                return (
                                    <tr key={cp.customer_page_id}>
                                        <td>{customer ? customer.customer_name : cp.customer_id}</td>
                                        <td>{page ? page.page_label : cp.page_id}</td>
                                        <td>{cp.custom_url}</td>
                                        <td>{cp.custom_schema_name}</td>
                                        <td>{cp.custom_table_name}</td>
                                        <td>
                                            <button onClick={() => handleEdit(cp)}>Edit</button>
                                            <button onClick={() => handleDelete(cp.customer_page_id)}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerPagesManagement;
