import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import './MenuManagement.css';

const MenuManagement = () => {
    const [menus, setMenus] = useState([]);
    const [pages, setPages] = useState([]);
    const [formData, setFormData] = useState({
        menu_name: '',
        menu_label: '',
        page_id: '',
        route: '',
        menu_order: '',
        is_active: true
    });
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchMenus();
        fetchPages();
    }, []);

    const fetchMenus = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/menu-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch menus');
            }
            const data = await response.json();
            setMenus(data);
        } catch (error) {
            console.error('Error fetching menus:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchPages = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch pages');
            }
            const data = await response.json();
            setPages(data);
        } catch (error) {
            console.error('Error fetching pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let url = 'https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/menu-crud';
            let method = 'POST';
            if (isEditing) {
                url += `/${formData.menu_id}`;
                method = 'PUT';
            }

            const { menu_id, ...payload } = formData;

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save menu');
            }

            await fetchMenus();
            resetForm();
            showAlert(isEditing ? 'Menu updated successfully' : 'Menu added successfully', 'success');
        } catch (error) {
            console.error('Error saving menu:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleEdit = (menu) => {
        setFormData(menu);
        setIsEditing(true);
    };

    const handleDelete = async (menu_id) => {
        if (window.confirm('Are you sure you want to delete this menu?')) {
            try {
                const response = await fetch(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/menu-crud/${menu_id}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete menu');
                }
                await fetchMenus();
                showAlert('Menu deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting menu:', error);
                showAlert(error.message, 'error');
            }
        }
    };

    const resetForm = () => {
        setFormData({
            menu_name: '',
            menu_label: '',
            page_id: '',
            route: '',
            menu_order: '',
            is_active: true
        });
        setIsEditing(false);
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    return (
        <div className="admin-container">
            <div className="admin-content">
                <AdminSidebar />
                <div className="admin-main-content">
                    <h2>Manage Menus</h2>
                    {alert.show && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <div className="menu-management-form-container">
                        <form onSubmit={handleSubmit} className="menu-form">
                            <input
                                type="text"
                                name="menu_name"
                                value={formData.menu_name}
                                onChange={handleInputChange}
                                placeholder="Menu Name"
                                required
                            />
                            <input
                                type="text"
                                name="menu_label"
                                value={formData.menu_label}
                                onChange={handleInputChange}
                                placeholder="Menu Label"
                                required
                            />
                            <select name="page_id" value={formData.page_id} onChange={handleInputChange} required>
                                <option value="">Select Page</option>
                                {pages.map(p => (
                                    <option key={p.page_id} value={p.page_id}>
                                        {p.page_label} ({p.page_name})
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="route"
                                value={formData.route}
                                onChange={handleInputChange}
                                placeholder="Route"
                                required
                            />
                            <input
                                type="number"
                                name="menu_order"
                                value={formData.menu_order}
                                onChange={handleInputChange}
                                placeholder="Menu Order"
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={formData.is_active}
                                    onChange={handleInputChange}
                                />
                                Is Active
                            </label>
                            <button type="submit">{isEditing ? 'Update' : 'Add'} Menu</button>
                            {isEditing && <button type="button" onClick={resetForm}>Cancel</button>}
                        </form>
                    </div>
                    <table className="menu-table">
                        <thead>
                            <tr>
                                <th>Menu Name</th>
                                <th>Menu Label</th>
                                <th>Page</th>
                                <th>Route</th>
                                <th>Order</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {menus.map(menu => {
                                const page = pages.find(p => p.page_id === menu.page_id);
                                return (
                                    <tr key={menu.menu_id}>
                                        <td>{menu.menu_name}</td>
                                        <td>{menu.menu_label}</td>
                                        <td>{page ? page.page_label : menu.page_id}</td>
                                        <td>{menu.route}</td>
                                        <td>{menu.menu_order}</td>
                                        <td>{menu.is_active ? 'Yes' : 'No'}</td>
                                        <td>
                                            <button onClick={() => handleEdit(menu)}>Edit</button>
                                            <button onClick={() => handleDelete(menu.menu_id)}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default MenuManagement;
