import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import './CustomerMenusManagement.css';

const CustomerMenusManagement = () => {
    const [customerMenus, setCustomerMenus] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [menus, setMenus] = useState([]);
    const [pages, setPages] = useState([]);
    const [customerPages, setCustomerPages] = useState([]);
    const [formData, setFormData] = useState({
        customer_id: '',
        menu_id: '',
        customer_page_id: '',
        is_active: true
    });
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchCustomers();
        fetchMenus();
        fetchPages();
        fetchCustomerPages();
        fetchCustomerMenus();
    }, []);

    const fetchCustomers = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customers');
            }
            const data = await response.json();
            setCustomers(data);
        } catch (error) {
            console.error('Error fetching customers:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchMenus = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/menu-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch menus');
            }
            const data = await response.json();
            setMenus(data);
        } catch (error) {
            console.error('Error fetching menus:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchPages = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch pages');
            }
            const data = await response.json();
            setPages(data);
        } catch (error) {
            console.error('Error fetching pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchCustomerPages = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-pages-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customer pages');
            }
            const data = await response.json();
            setCustomerPages(data);
        } catch (error) {
            console.error('Error fetching customer pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const fetchCustomerMenus = async () => {
        try {
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-menus-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch customer menus');
            }
            const data = await response.json();
            setCustomerMenus(data);
        } catch (error) {
            console.error('Error fetching customer menus:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // If adding a new record, perform uniqueness checks
        if (!isEditing) {
            // Check if this menu is already assigned to the customer
            const menuExistsForCustomer = customerMenus.some(cm =>
                cm.customer_id.toString() === formData.customer_id.toString() &&
                cm.menu_id.toString() === formData.menu_id.toString()
            );
            if (menuExistsForCustomer) {
                showAlert('This customer already has this menu assigned.', 'error');
                return;
            }

            // If a customer_page_id is selected, ensure it's not already assigned
            if (formData.customer_page_id) {
                const pageExistsForCustomer = customerMenus.some(cm =>
                    cm.customer_id.toString() === formData.customer_id.toString() &&
                    cm.customer_page_id &&
                    cm.customer_page_id.toString() === formData.customer_page_id.toString()
                );
                if (pageExistsForCustomer) {
                    showAlert('This customer already has this custom page assigned.', 'error');
                    return;
                }
            }
        }

        try {
            let url = 'https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-menus-crud';
            let method = 'POST';
            if (isEditing) {
                url += `/${formData.customer_menu_id}`;
                method = 'PUT';
            }

            const { customer_menu_id, ...payload } = formData;
            // Convert empty customer_page_id to null
            if (!payload.customer_page_id) {
                payload.customer_page_id = null;
            }

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save customer menu');
            }

            await fetchCustomerMenus();
            resetFormPartial(); // Keep the selected customer
            showAlert(isEditing ? 'Customer menu updated successfully' : 'Customer menu added successfully', 'success');
        } catch (error) {
            console.error('Error saving customer menu:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleEdit = (cm) => {
        setFormData(cm);
        setIsEditing(true);
    };

    const handleDelete = async (customer_menu_id) => {
        if (window.confirm('Are you sure you want to delete this customer menu?')) {
            try {
                const response = await fetch(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/customer-menus-crud/${customer_menu_id}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete customer menu');
                }
                await fetchCustomerMenus();
                showAlert('Customer menu deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting customer menu:', error);
                showAlert(error.message, 'error');
            }
        }
    };

    const resetFormPartial = () => {
        // Keep the selected customer_id
        setFormData(prev => ({
            ...prev,
            menu_id: '',
            customer_page_id: '',
            is_active: true,
            customer_menu_id: undefined
        }));
        setIsEditing(false);
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    // Filter customerMenus by selected customer_id
    const filteredCustomerMenus = customerMenus.filter(cm => {
        if (!formData.customer_id) return false;
        return cm.customer_id.toString() === formData.customer_id.toString();
    });

    // Filter customer_pages by selected customer_id
    const filteredCustomerPages = customerPages.filter(cp => {
        if (!formData.customer_id) return false;
        return cp.customer_id.toString() === formData.customer_id.toString();
    });

    return (
        <div className="admin-container">
            <div className="admin-content">
                <AdminSidebar />
                <div className="admin-main-content">
                    <h2>Manage Customer Menus</h2>
                    {alert.show && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <div className="customer-menus-form-container">
                        <form onSubmit={handleSubmit} className="customer-menus-form">
                            <select name="customer_id" value={formData.customer_id} onChange={handleInputChange} required>
                                <option value="">Select Customer</option>
                                {customers.map(c => (
                                    <option key={c.customer_id} value={c.customer_id}>
                                        {c.customer_name} ({c.customer_email})
                                    </option>
                                ))}
                            </select>
                            <select name="menu_id" value={formData.menu_id} onChange={handleInputChange} required>
                                <option value="">Select Menu</option>
                                {menus.map(m => (
                                    <option key={m.menu_id} value={m.menu_id}>
                                        {m.menu_label} ({m.menu_name})
                                    </option>
                                ))}
                            </select>
                            {/* Customer Page Dropdown (Optional) */}
                            <select name="customer_page_id" value={formData.customer_page_id} onChange={handleInputChange}>
                                <option value="">No Custom Page</option>
                                {filteredCustomerPages.map(cp => {
                                    const page = pages.find(p => p.page_id === cp.page_id);
                                    const pageLabel = page ? page.page_label : `Page ${cp.page_id}`;
                                    const displayName = cp.custom_url ? `${pageLabel} [${cp.custom_url}]` : pageLabel;
                                    return (
                                        <option key={cp.customer_page_id} value={cp.customer_page_id}>
                                            {displayName}
                                        </option>
                                    );
                                })}
                            </select>
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={formData.is_active}
                                    onChange={handleInputChange}
                                />
                                Is Active
                            </label>
                            <button type="submit">{isEditing ? 'Update' : 'Add'} Customer Menu</button>
                            {isEditing && <button type="button" onClick={resetFormPartial}>Cancel</button>}
                        </form>
                    </div>
                    <table className="customer-menus-table">
                        <thead>
                            <tr>
                                <th>Customer</th>
                                <th>Menu</th>
                                <th>Custom Page</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredCustomerMenus.map((cm) => {
                                const customer = customers.find(c => c.customer_id === cm.customer_id);
                                const menu = menus.find(m => m.menu_id === cm.menu_id);
                                let customPageDisplay = '';
                                if (cm.customer_page_id) {
                                    const cp = customerPages.find(cpg => cpg.customer_page_id === cm.customer_page_id);
                                    if (cp) {
                                        const page = pages.find(p => p.page_id === cp.page_id);
                                        const pageLabel = page ? page.page_label : `Page ${cp.page_id}`;
                                        customPageDisplay = cp.custom_url ? `${pageLabel} [${cp.custom_url}]` : pageLabel;
                                    }
                                }

                                return (
                                    <tr key={cm.customer_menu_id}>
                                        <td>{customer ? customer.customer_name : cm.customer_id}</td>
                                        <td>{menu ? menu.menu_label : cm.menu_id}</td>
                                        <td>{customPageDisplay || 'None'}</td>
                                        <td>{cm.is_active ? 'Yes' : 'No'}</td>
                                        <td>
                                            <button onClick={() => handleEdit(cm)}>Edit</button>
                                            <button onClick={() => handleDelete(cm.customer_menu_id)}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerMenusManagement;
