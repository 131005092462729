import React from 'react';
import { NavLink } from 'react-router-dom';
import './AdminSidebar.css';

const AdminSidebar = () => {
    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <div className="logo-text">Admin Dashboard</div>
            </div>
            <div className="menuContainer">
                <NavLink to="/admin" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Main Admin Page
                </NavLink>
                <NavLink to="/admin-select-customer" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Impersonate Customer
                </NavLink>
                <NavLink to="/customer-enrollment" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Customer Enrollment
                </NavLink>
                <NavLink to="/pages-management" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Pages Management
                </NavLink>
                <NavLink to="/customer-pages-management" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Customer Pages Management
                </NavLink>
                <NavLink to="/menus-management" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Menu Management
                </NavLink>
                <NavLink to="/customer-menus-management" className={({ isActive }) => isActive ? 'sidebarButton active' : 'sidebarButton'}>
                    Customer Menus Management
                </NavLink>
            </div>
        </div>
    );
};

export default AdminSidebar;
