import React, { useState, useEffect } from 'react';
import AdminSidebar from './AdminSidebar';
import './PageManagement.css';

const PageManagement = () => {
    const [pages, setPages] = useState([]);
    const [formData, setFormData] = useState({
        page_name: '',
        page_label: '',
        schema_name: '',
        table_name: '',
        is_active: true
    });
    const [isEditing, setIsEditing] = useState(false);
    const [alert, setAlert] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchPages();
    }, []);

    const fetchPages = async () => {
        try {
            // Update this URL to your API endpoint
            const response = await fetch('https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud');
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to fetch pages');
            }
            const data = await response.json();
            setPages(data);
        } catch (error) {
            console.error('Error fetching pages:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let url = 'https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud';
            let method = 'POST';
            if (isEditing) {
                url += `/${formData.page_id}`;
                method = 'PUT';
            }

            const { page_id, ...payload } = formData;

            const response = await fetch(url, {
                method,
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(payload),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to save page');
            }

            await fetchPages();
            resetForm();
            showAlert(isEditing ? 'Page updated successfully' : 'Page added successfully', 'success');
        } catch (error) {
            console.error('Error saving page:', error);
            showAlert(error.message, 'error');
        }
    };

    const handleEdit = (page) => {
        setFormData(page);
        setIsEditing(true);
    };

    const handleDelete = async (pageId) => {
        if (window.confirm('Are you sure you want to delete this page?')) {
            try {
                const response = await fetch(`https://ooozlpj913.execute-api.us-west-1.amazonaws.com/prod/page-crud/${pageId}`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (!response.ok) {
                    const errorData = await response.json();
                    throw new Error(errorData.error || 'Failed to delete page');
                }
                await fetchPages();
                showAlert('Page deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting page:', error);
                showAlert(error.message, 'error');
            }
        }
    };

    const resetForm = () => {
        setFormData({
            page_name: '',
            page_label: '',
            schema_name: '',
            table_name: '',
            is_active: true
        });
        setIsEditing(false);
    };

    const showAlert = (message, type) => {
        setAlert({ show: true, message, type });
        setTimeout(() => setAlert({ show: false, message: '', type: '' }), 5000);
    };

    return (
        <div className="admin-container">
            <div className="admin-content">
                <AdminSidebar />
                <div className="admin-main-content">
                    <h2>Manage Pages</h2>
                    {alert.show && (
                        <div className={`alert ${alert.type}`}>
                            {alert.message}
                        </div>
                    )}
                    <div className="page-management-form-container">
                        <form onSubmit={handleSubmit} className="page-form">
                            <input
                                type="text"
                                name="page_name"
                                value={formData.page_name}
                                onChange={handleInputChange}
                                placeholder="Page Name"
                                required
                            />
                            <input
                                type="text"
                                name="page_label"
                                value={formData.page_label}
                                onChange={handleInputChange}
                                placeholder="Page Label"
                                required
                            />
                            <input
                                type="text"
                                name="schema_name"
                                value={formData.schema_name}
                                onChange={handleInputChange}
                                placeholder="Schema Name"
                            />
                            <input
                                type="text"
                                name="table_name"
                                value={formData.table_name}
                                onChange={handleInputChange}
                                placeholder="Table Name"
                            />
                            <label>
                                <input
                                    type="checkbox"
                                    name="is_active"
                                    checked={formData.is_active}
                                    onChange={handleInputChange}
                                />
                                Is Active
                            </label>
                            <button type="submit">{isEditing ? 'Update' : 'Add'} Page</button>
                            {isEditing && <button type="button" onClick={resetForm}>Cancel</button>}
                        </form>
                    </div>
                    <table className="page-table">
                        <thead>
                            <tr>
                                <th>Page Name</th>
                                <th>Page Label</th>
                                <th>Schema Name</th>
                                <th>Table Name</th>
                                <th>Active</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pages.map((page) => (
                                <tr key={page.page_id}>
                                    <td>{page.page_name}</td>
                                    <td>{page.page_label}</td>
                                    <td>{page.schema_name}</td>
                                    <td>{page.table_name}</td>
                                    <td>{page.is_active ? 'Yes' : 'No'}</td>
                                    <td>
                                        <button onClick={() => handleEdit(page)}>Edit</button>
                                        <button onClick={() => handleDelete(page.page_id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PageManagement;
