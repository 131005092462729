import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import CustomAuthenticator from './CustomAuthenticator';
import Index from './pages/Index';
import Overview from './pages/Overview';
import AdminSelection from './pages/AdminSelection';
import Admin from './pages/Admin';
import CustomerEnrollment from './pages/CustomerEnrollment';
import GoogleAdsGA from './pages/GoogleAdsGA';
import GoogleAdsSC from './pages/GoogleAdsSC';
import FacebookAdsSummary from './pages/FacebookAdsSummary';
import Sales from './pages/Sales';
import SignedOut from './pages/SignedOut';
import MarketingSimulations from './pages/MarketingSimulations';
import MarketingSpend from './pages/MarketingSpend';
import YouTube from './pages/YouTube';
import ProtectedRoute from './ProtectedRoute';
import TopBar from './pages/TopBar';
import { CustomerProvider } from './CustomerContext';
import PageManagement from './pages/PageManagement';
import CustomerPagesManagement from './pages/CustomerPagesManagement';
import MenuManagement from './pages/MenuManagement';
import CustomerMenusManagement from './pages/CustomerMenusManagement';

import './App.css';

// External Page Components
import OverviewExternal from './pages/OverviewExternal';
import GoogleAdsGAExternal from './pages/GoogleAdsGAExternal';
import GoogleAdsSCExternal from './pages/GoogleAdsSCExternal';
import FacebookExternal from './pages/FacebookExternal';
import SpendSimulationsExternal from './pages/SpendSimulationsExternal';
import MarketingSpendCasesExternal from './pages/MarketingSpendCasesExternal';
import YouTubeExternal from './pages/YouTubeExternal';
import IFramePage from './pages/IFramePage';

const App = () => {
    const location = useLocation();

    const showTopBar = location.pathname !== '/' && location.pathname !== '/signed-out';

    const getPageClass = () => {
        if (location.pathname === '/') {
            return 'login-page';
        } else if (showTopBar) {
            return 'overview-page';
        }
        return '';
    };

    return (
        <div className={`app-container ${getPageClass()}`}>
            <CustomerProvider>
                <CustomAuthenticator>
                    {(isAuthenticated, handleSignOut) => (
                        <div>
                            {showTopBar && isAuthenticated && <TopBar handleSignOut={handleSignOut} />}
                            <main className="main-content">
                                <Routes>
                                    <Route path="/" element={<Index />} />
                                    <Route path="/admin-select-customer" element={<ProtectedRoute component={AdminSelection} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/admin" element={<ProtectedRoute component={Admin} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/customer-enrollment" element={<ProtectedRoute component={CustomerEnrollment} isAuthenticated={isAuthenticated} />} />

                                    <Route path="/pages-management" element={<ProtectedRoute component={PageManagement} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/customer-pages-management" element={<ProtectedRoute component={CustomerPagesManagement} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/menus-management" element={<ProtectedRoute component={MenuManagement} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/customer-menus-management" element={<ProtectedRoute component={CustomerMenusManagement} isAuthenticated={isAuthenticated} />} />

                                    <Route path="/overview" element={<ProtectedRoute component={Overview} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/google-ads-ga" element={<ProtectedRoute component={GoogleAdsGA} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/google-ads-sc" element={<ProtectedRoute component={GoogleAdsSC} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/facebook" element={<ProtectedRoute component={FacebookAdsSummary} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/spend-simulations" element={<ProtectedRoute component={MarketingSimulations} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/marketing-spend-cases" element={<ProtectedRoute component={MarketingSpend} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/youtube" element={<ProtectedRoute component={YouTube} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/iframe" element={<ProtectedRoute component={IFramePage} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/overview-external" element={<ProtectedRoute component={OverviewExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/google-ads-ga-external" element={<ProtectedRoute component={GoogleAdsGAExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/google-ads-sc-external" element={<ProtectedRoute component={GoogleAdsSCExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/facebook-external" element={<ProtectedRoute component={FacebookExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/spend-simulations-external" element={<ProtectedRoute component={SpendSimulationsExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/marketing-spend-cases-external" element={<ProtectedRoute component={MarketingSpendCasesExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/youtube-external" element={<ProtectedRoute component={YouTubeExternal} isAuthenticated={isAuthenticated} />} />
                                    <Route path="/sales" element={<Sales />} />
                                    <Route path="/signed-out" element={<SignedOut />} />
                                </Routes>
                            </main>
                        </div>
                    )}
                </CustomAuthenticator>
            </CustomerProvider>
        </div>
    );
};

const AppWrapper = () => (
    <Router>
        <App />
    </Router>
);

export default AppWrapper;